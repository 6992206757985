import './App.css';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import useWindowSize from "./utils/useWindowSize";
import HeaderLayout from '../src/component/header/header.js';
import FooterLayout from '../src/component/footer/footer.js';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

function App() {

  const classes = useStyles();
  const { width } = useWindowSize();

  return (
    <div className={classes.root}>
            
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}><HeaderLayout></HeaderLayout></Paper>
                </Grid>
                <Grid item xs={12}>
                    <FooterLayout></FooterLayout>
                </Grid>
            </Grid>
        </div>
  );
}

export default App;
