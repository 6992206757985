import Grid from '@material-ui/core/Grid';
import MainPhoto from '../img/main.jpg';
import Pulse from 'react-reveal/Pulse';
import './css/aboutus.css';

function AboutUs(){
    return(
        <div className="aboutusmain">
            <Grid container spacing={0}>
               
                <Grid item xs={12}>
        
                    <Grid container spacing={0}>
                        <Grid item xs={12} sm={6}>
                                <Pulse>
                                    <img src={MainPhoto} alt="box" id="main"/>
                                </Pulse>
                        </Grid>

                        <Grid item xs={12} sm={5}>
                            <p id="p_about">
                                <span id="span1">Welcome to our store<br></br><br></br></span>

                                <span id="span2">About Ceylon Precious<br></br>Ltd<br></br></span>
                                <span id="span3">Ceylon Precious Ltd is a UK based technology<br></br>company that finds the perfect product for the<br></br>perfect buyer with no middle man.<br></br><br></br></span>

                                We believe in the promise of free and fair trade.<br></br><br></br><br></br>

                                Working directly with the source allows us to cut out unethical<br></br>‘middlemen’ to ensure that both buyers and sellers get exactly what they<br></br>need.<br></br><br></br>

                                As a part of this promise, we also guarantee on authenticity, legality,<br></br>export permits, licenses, transparency and traceability. We audit,<br></br>visit, and revisit each location in person, to ensure we keep our promises, but<br></br>most importantly to build partnerships and friendships.<br></br><br></br>

                                A key aim of Ceylon Precious Ltd is to generate funds which are invested<br></br>directly back into ALL COMMUNITIES WORLD WIDE.<br></br><br></br>

                                We are committed to protecting the natural environment, whilst<br></br>improving working conditions, medical care and education.<br></br><br></br>
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
    
}

export default AboutUs;