import './css/header.css';
import Logo_Cp from '../../img/cp-logo.png';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import AboutUs from '../../ui/aboutus';
import Home from '../../ui/home';
import './css/header.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export const Header =(props)=>{
    const classes = useStyles();
    return(
      <Router>
      <dev class="headerMain">
          <img src={Logo_Cp} alt="box" id="cp_logo"/><br></br><br></br>
          <hr></hr>
          <Grid item xs={12} spacing={0} className={classes.paper}>
                    <ul id="top_menu">
                        <li><Link to="/">HOME</Link></li>
                        <li> </li>
                        <li> </li>
                        <li> </li>
                        <li> </li>
                        <li><Link to="/about">ABOUT US</Link></li>
                    </ul>
                </Grid>
                <hr></hr>
      </dev>
      <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/about">
            <AboutUs />
          </Route>
        </Switch>
      </Router>
    );
  
}

export default Header;