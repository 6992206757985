import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import One from '../img/1.jpg';
import Two from '../img/2.jpg';
import Three from '../img/3.jpg';
import Four from '../img/4.jpg';
import Button from '@material-ui/core/Button';
import Slide from 'react-reveal/Slide';
import './css/home.scss';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }));
  

function Home(){
    const classes = useStyles();

    return(
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Grid container spacing={0}>
                            <Grid item xs={12} sm={6}>
                                <Slide left>
                                    <img src={One} alt="box" id="category"/>
                                </Slide>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <p id="p_home_left">
                                <br></br>
                                <span id="span1home">Tea<br></br></span>
                                Ceylon tea is one of the oldest and most popular types of black tea<br></br>also known as Sri Lankan Tea.Being a favorite beverage of many tea<br></br>drinkers, we supply single source tea catering even more satisfaction<br></br>to our customers.
                                </p>
                                <Button variant="contained" id="buttonleft">
                                    contact us
                                </Button>
                            </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={0}>
                            <Grid item xs={12} sm={6}>
                                <p id="p_home_right">
                                <br></br>
                                <span id="span1home">Gemstones<br></br></span>
                                Gem industry in Sri Lanka is well known for over 2000 years. It has<br></br>been the birthplace for more than 30 types of gems and has produced<br></br>one of the rarest stunning priceless ones.Whilst having access to all<br></br>types of gems in Sri Lanka, we also possess the ability to source rare<br></br>types of collector gems.<br></br>
                                </p>
                                <Button variant="contained" id="button">
                                    contact us
                                </Button>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Slide right>
                                    <img src={Two} alt="box" id="category"/>
                                </Slide>
                            </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={0}>
                            <Grid item xs={12} sm={6}>
                                <Slide left>
                                    <img src={Three} alt="box" id="category"/>
                                </Slide>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <p id="p_home_left">
                                <br></br>
                                <span id="span1home">IT SOLUTIONS<br></br></span>
                                We help our clients with Transformative Digital Services that connect,<br></br>engage and drive enterprises. Ceylon Precious Ltd has the capabilities<br></br>to provide comprehensive services and solutions portfolio from our<br></br>solution providers to enhance and differentiate our clients’<br></br>businesses. Our engagement model allows us to be responsive to<br></br>ever-changing markets and technologies, creating long-term business<br></br>partnerships.<br></br>
                                </p>
                                <Button variant="contained" id="buttonleft">
                                    contact us
                                </Button>
                            </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={0}>
                            <Grid item xs={12} sm={6}>
                                <p id="p_home_right">
                                <br></br>
                                <span id="span1home">Spices<br></br></span>
                                Sri Lanka is well known for its spices from ancient times as these grow<br></br>in abundance all over the Island nation.<br></br>
                                </p>
                                <Button variant="contained" id="button">
                                    contact us
                                </Button>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Slide right>
                                    <img src={Four} alt="box" id="category"/>
                                </Slide>
                            </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
    
}

export default Home;