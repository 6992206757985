import Grid from '@material-ui/core/Grid';
import Logo_Cp from '../../img/logowhite.png';
import Facebook from '../../img/facebook-white.png';
import Instagram from '../../img/instagram-white.png';
import Twitter from '../../img/twitter-white.png';
import Pinterest from '../../img/pinterest-white.png';
import Youtube from '../../img/youtube.png';

import './css/footer.css';

function Footer(){
    
    return(
        <div class = "footerMain">
            <div class= "mainfooter">
            <Grid container spacing={0}>
                <Grid item xs={12} sm={1}>
                     
                </Grid>
                <Grid item xs={12} sm={3}>
                    <img src={Logo_Cp} alt="box" id="cp_logo_footer"/>
                    <p>
                        © 2020 Ceylon Precious Ltd.<br></br>

                        <span id="span">Ceylon Precious Ltd (company number 10312906),<br></br>
                        A company registered in England and Wales.<br></br><br></br></span>

                        4 Old Park Lane, Mayfair, London, England, W1K 1QW<br></br><br></br>

                        <span id="span">Ceylon Precious Ltd. All rights reserved.<br></br><br></br></span>
                    </p>
            
                </Grid>

                <Grid item xs={12} sm={3}>
                    
                        <ul>
                            <li>Privacy Policy</li>
                            <hr></hr>
                            <li>Terms of Use</li>
                            <hr></hr>
                            <li>Link to Ceylon Precious Ltd.</li>
                            <hr></hr>
                            <li>About Us</li>
                        </ul>
                </Grid>

                <Grid item xs={12} sm={1}>
                     
                </Grid>

                <Grid item xs={12} sm={4}>
                    <br></br><br></br>
                    <h2>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Follow us</h2>

                    <table>
                        <tbody>
                            <tr>
                                <td valign="top">
                                    <a href="https://www.facebook.com/Ceylon-Precious-Ltd-845223658952461/" target="blank"><img src={Facebook} width="20" height="20" alt="" /></a>
                                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    <a href="#" ><img src={Twitter} width="20" height="20" alt="" /></a>
                                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    <a href="#"><img src={Instagram} width="20" height="20" alt="" /></a>
                                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    <a href="#" ><img src={Pinterest} width="20" height="20" alt="" /></a>
                                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    <a href="#" ><img src={Youtube} width="20" height="20" alt="" /></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Grid>

                <Grid item xs={12} sm={1}>
                     
                </Grid>

            </Grid>
            </div>
        </div>
    );
}

export default Footer;